@import "../frontend/src/variables.module";

#about {
  position: relative;
  width: 100%;
  float: right;
  box-sizing: border-box;
  background: $accent_dark;
  z-index: 10;

  .scrollArrow {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 50%;
    color: $accent_light;
    display: none;
    z-index: 10;
    font-size: 4rem;
  }

  &.scrolled {

    .scrollArrow {
      display: block;
      font-size: 4rem;
      opacity: 0;
      animation: arrowDown 2s ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-delay: 3s;
    }
  }

  @media (prefers-color-scheme: dark) {
    & {
      background: $background_2_dark;
    }
  }

  .backgroundBall {
    display: none;
    position: absolute;
    width: 200vw;
    height: 200vw;
    background: $accent_dark;
    margin-right: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);

    z-index: 0;
    border-radius: 50%;
    @media (prefers-color-scheme: dark) {
      & {
        background: $background_2_dark;
      }
    }
  }

  .separator {
    fill: $background_light;

    @media (prefers-color-scheme: dark) {
      & {
        fill: $background_dark;
      }
    }
  }

  .parallax {
    position: relative;
  }

  .contentWrapper {
    color: black;
    transition: transform 0.5s ease-out;

    @media (prefers-color-scheme: dark) {
      & {
        color: white;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;
      justify-content: center;
      justify-items: center;
      padding: 0;
      margin-top: -5%;


      img {
        width: 100%;
        border-radius: $border_radius;
      }

      .text, .image {
        padding: 2rem;
      }

      .image {
        flex: 1;
        transform: scale(1.2) translateX(5%);
      }

      .text {
        flex: 0.9;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        min-width: 400px;
        transform: translate(5%, 0%);

        h1, h2 {
          text-align: left;
        }

        h1 {
          font-size: 3rem;
          color: $headline_light;
        }

        h2 {
          color: $accent_light;
          font-weight: normal;

          @media (prefers-color-scheme: dark) {
            & {
              color: $accent_light;
            }
          }
        }

        .paragraphs {
          padding: 1rem;
          box-sizing: border-box;
          color: $font_light;

          .card {
            margin-top: 1rem;
            padding: 1rem;
            display: flex;

            svg {
              padding: 1rem;
              margin-right: 2rem;
              font-size: 2rem;
              border-radius: 4rem;

              &.colleagues {
                color: $accent_dark;
                background: $accent_light;
              }

              &.bubbles {
                color: $accent_dark;
                background: $accent_light;
              }
            }

            .paragraph {
              h2 {
                margin-top: 0;
              }

              flex: 1;
              padding: 0;
              max-width: 80%;
            }
          }
        }
      }

      @media screen and (max-width: 1000px) {
        .image {
          transform: none;
          min-width: 100%;
          padding: 2rem;
          box-sizing: border-box;
        }

        .text {
          flex: 1;
          min-width: 100%;
          box-sizing: border-box;
          transform: translate(0, 0);
          padding: 0 3rem;
        }
      }
    }


    .join {
      text-align: center;
      width: 50%;
      margin: 1rem auto;
      box-sizing: border-box;
      background: $foreground_light;
      color: $accent_dark;
      box-shadow: $shadow;

      @media (prefers-color-scheme: dark) {
        & {
          color: $foreground_dark;
          background: $accent_light;
        }
      }
    }
  }

  .youtubeWrapper {
    width: 100%;
    padding: 0 2rem 15%;
    box-sizing: border-box;

    h1 {
      color: $headline_light;
    }

    h2 {
      color: $accent_light;
    }

    .youtube {
      z-index: 13;
      position: relative;
      height: 30rem;
      width: 100%;
      max-width: 800px;
      box-shadow: $shadow;
      margin: 4rem auto;

      @media (prefers-color-scheme: dark) {
        & {
          box-shadow: $shadow_dark;
        }
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  .partners {
    h1 {
      color: $headline_light;
    }

    h2 {
      color: $accent_light;
    }

    .partnerWrapper {
      padding: 2rem;

      .partner {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        align-content: center;
        justify-items: center;
        justify-content: center;
        margin: auto;
        padding: 1rem;
        border-radius: $border_radius;
        box-shadow: $shadow;

        background: white;


        a {
          width: 15%;
          padding: 1rem;

          img {
            width: 100%;
          }
        }
      }

    }

    @media (prefers-color-scheme: dark) {
      .partnerWrapper .partner {
        background: $background_dark;
        box-shadow: $shadow_dark;
      }
    }
  }

}

@keyframes arrowDown {
  from {
    transform: translate(-50%, -7%);
    opacity: 0;
  }
  75% {
    transform: translate(-50%, 7%);
  }
  to {
    transform: translate(-50%, 7%);
    opacity: 1;
  }
}
