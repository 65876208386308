@import "../frontend/src/variables.module";

#contact {
  position: relative;
  overflow: hidden;
  padding: 4rem 0;

  background: $background_light;
  @media (prefers-color-scheme: dark) {
    & {
      background: $background_dark;
    }
  }

  h1 {
    color: $accent_light;
  }

  h2 {
    color: white;
  }

  .contentWrapper {
    padding: 2rem;
  }

  .backgroundBall {
    z-index: 0;
    display: block;
    position: absolute;
    width: 200vw;
    height: 200vw;
    left: 50%;
    top: 0;
    border-radius: 50%;
    transform: translate(-50%, 0);

    background: $accent_dark;
    @media (prefers-color-scheme: dark) {
      & {
        background: $background_2_dark;
      }
    }
  }

  .aboutCards {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a.card {
      box-shadow: $shadow;
      color: $accent_light;
      flex-shrink: 1;
      flex-grow: 1;
      width: 300px;
      margin: 0.75rem;
      padding: 2rem;
      text-align: center;
      border-radius: $border_radius;
      transition: transform 0.2s, opacity 0.2s, color 0.2s;
      background: $background_light;

      @media (prefers-color-scheme: dark) {
        & {
          color: $accent_dark;
          background: $background_2_dark;
          box-shadow: $shadow_dark;
        }
      }

      svg {
        display: block;
        margin: 1rem auto 2rem;
        font-size: 3rem;
        transition: transform 0.2s, opacity 0.2s, color 0.3s;
      }

      &:hover {
        opacity: 1;
        transform: translateY(-5px) scale(1.01);

        .insta {
          color: red;
        }

        .linkedin {
          color: #417efc;
        }

        .mail {
          color: $accent_dark;

          @media (prefers-color-scheme: dark) {
            & {
              color: $accent_light;
            }
          }
        }
      }

      label {
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: bolder;
        color: $headline_2_light;
      }

      p {
        color: black;
      }
    }
  }

  form {
    display: grid;
    grid-template-columns: auto auto;
    padding: .5rem;
    gap: 1rem;
    margin-bottom: 5rem;

    input[type=text], input[type=telephone], input[type=email], select, textarea {
      width: 100%;
      padding: 1rem;
      border: none;
      border-radius: $border_radius;
      box-sizing: border-box;
      margin-top: 6px;
      resize: vertical;
      font-size: 1rem;
    }

    .message {
      grid-column: 1 / span 2;

      textarea {
        height: 10rem;
      }
    }

    .span-2 {
      grid-column: span 2;
    }

    .buttons {
      grid-column: span 2;

      .button {
        transition: transform 0.2s, opacity 0.2s;
        color: $button_font_dark;
        padding: 1rem 2rem;
        font-size: 1.2rem;
        border-radius: 2rem;
        width: 50%;
        cursor: pointer;
  
        text-align: center;
        margin: 1rem auto;
        box-sizing: border-box;
        background: $foreground_light;
        color: $accent_dark;
        box-shadow: $shadow;
  
        @media (prefers-color-scheme: dark) {
          & {
            color: $foreground_dark;
            background: $accent_light;
          }
        }
      
        &:hover {
          transform: translateX(5px);
          opacity: 0.5;
        }
      }
    }
    

    p {
      padding: 0;
      margin: 0;
    }

    label {
      text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: bolder;
        color: $headline_light;
    }
    
    .container {
      border-radius: 5px;
      background-color: #f2f2f2;
      padding: 20px;
    }
  }
    
}
