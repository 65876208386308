@import "../frontend/src/variables.module";

.scenarios {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: $background_light; // #c8c8c8;

  @media (prefers-color-scheme: dark) {
    & {
      background: $background_dark;
    }
  }

  .separator {
    fill: $accent_dark;

    @media (prefers-color-scheme: dark) {
      & {
        fill: $background_2_dark;
      }
    }
  }

  .contentWrapper {
    position: relative;
    margin: 0 auto;
    padding-bottom: 5%;
    padding-top: 2rem;
    flex-flow: row wrap;
    align-content: stretch;
    justify-content: space-around;
    width: 100%;

    .scenarioWindow {
      color: black;
      $scenarioWidth: 500px;
      $scenarioHeight: 700px;
      width: $scenarioWidth;
      max-width: 90vw;
      height: $scenarioHeight;
      margin: 1rem auto;

      .scenarioWrapper {
        display: flex;
        transition: transform 0.5s;
        position: absolute;
        perspective: 5000px;

        .scenario {
          height: $scenarioHeight;
          width: $scenarioWidth;
          max-width: 90vw;
          background: white;
          color: black;
          box-sizing: border-box;
          margin: 0 5vw 0 0;
          flex-shrink: 0;
          border-radius: $border_radius;
          position: relative;
          transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;

          .backgroundImg {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            transition: opacity 0.5s, transform 0.5s;
            border-radius: $border-radius;
          }

          &.active {
            opacity: 1;
            box-shadow: $shadow;

            @media (prefers-color-scheme: dark) {
              & {
                box-shadow: $shadow_dark;
              }
            }
          }

          h2 {
            margin: 0;
            text-align: center;
            transition: 0.4s;
            transition-delay: 0.1s;
            font-size: 3vh;
          }


          .titleSheet {

            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;

            width: 600px;
            height: 600px;
            transform: translateY(-50%) translateX(-50px);
            padding: 3rem;
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            margin-top: auto;
            max-width: 100vw;
            backdrop-filter: blur(4px);
            margin-bottom: 0;
            transition: opacity 0.7s ease-out;
            border-radius: 300px;

            color: black;
            background: rgba(255, 255, 255, 0.5);

            p {
              color: $button_font_light;
              padding: 0 100px;
              text-align: center;
              transition: opacity 0.5s;
            }

            @media only screen and (max-width: 600px) {
              & {
                width: 100%;
                height: 100%;
                transform: translateY(-50%) translateX(0);
                padding: 2rem;
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                margin-top: auto;
                backdrop-filter: blur(4px);
                margin-bottom: 0;
                transition: opacity 0.7s ease-out;
                border-radius: $border_radius;

                p {
                  padding: 0;
                }
              }
            }

            @media (prefers-color-scheme: dark) {
              & {
                color: white;
                background: rgba(0, 0, 0, 0.5);

                p {
                  color: $button_font_dark;
                }
              }
            }
          }

          &.inactive {
            opacity: 0.3;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            transform: scale(0.95) translateY(5%);
            user-select: none;
            transition: transform 0.2s, opacity 0.2s;

            @media (prefers-color-scheme: dark) {
              & {
                box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
              }
            }

            &:hover {
              opacity: 0.5;
              transform: scale(0.97) translateY(5%);
            }

            .titleSheet .buttons {
              *:hover {
                opacity: 1 !important;
                cursor: auto;
              }
            }

            .titleSheet p {
              opacity: 0;
            }

            .titleSheet h2 {
              transform: scale(1.3) translateY(100px);
            }
          }
        }
      }
    }
  }

  .scenarioNavigation {
    position: relative;
    display: block;
    color: $button_font_light;
    text-align: center;
    width: auto;
    margin: 0 auto;
    padding: 2vw 0 5vw;

    @media (prefers-color-scheme: dark) {
      & {
        color: $button_font_dark;
      }
    }

    .navigationWrapper {
      display: block;

      div {
        display: inline-block;
        margin: 10px;
        transition: transform 0.2s, color 0.2s;

        &:hover {
          color: rgba(17, 25, 33, 1);
          transform: translateY(-10%);
          cursor: pointer;
        }
      }
    }

    .shuffle {
      align-content: center;
      display: block;
      margin: 0 auto;
      transition: transform 0.2s, color 0.2s;

      &:hover {
        color: rgba(17, 25, 33, 1);
        transform: translateY(-10%);
      }
    }

    .tooltip {
      font-size: 0.7em;
      font-weight: normal;
      color: $accent_light;
      transition: 0.1s;

      &.invisible {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}