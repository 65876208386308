@import "../frontend/src/variables.module";

.joinButton {
  transition: transform 0.2s, opacity 0.2s;
  color: $button_font_dark;
  background: $accent_dark;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  border-radius: 2rem;
  width: 50%;
  cursor: pointer;

  @media (prefers-color-scheme: dark) {
    color: $button_font_light;
    background: $accent_light;
  }

  &:hover {
    transform: translateX(5px);
    opacity: 0.5;
  }
}

.links {
  font-size: 2rem;
  margin: 1rem 2rem 1rem 0;

  a {
    display: flex;
    align-items: center;
    align-content: center;
    float: left;
    padding: 0.5rem;
    margin-right: 1rem;
    color: $accent_dark;
    background: $background_2_light;
    border-radius: 2rem;

    @media (prefers-color-scheme: dark) {
      & {
        color: $accent_light;
        background: none;
      }
    }
  }
}
