@import "../frontend/src/variables.module";

#landing {
  width: 100%;
  max-height: 100vh;
  position: relative;
  background: $background_light;
  overflow: hidden;

  @media (prefers-color-scheme: dark) {
    & {
      background: $background_dark;
    }
  }

  #intro {
    width: 100%;
    max-height: 100vh;
    position: relative;

    .separator {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%) translateY(1px);
      fill: $accent_dark;

      z-index: 5;

      @media (prefers-color-scheme: dark) {
        & {
          fill: $background_2_dark;
        }
      }
    }

    .contentWrapper {
      position: relative;
      width: 100%;
      max-height: 100vh;
      min-height: 90vh;
      margin: auto;

      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      justify-items: center;
      flex-wrap: wrap;
      flex-direction: row-reverse;

      @media screen and (max-width: 1000px) {
        min-height: 100vh;
      }

      .screenshot {
        max-width: 600px;
        padding: 0 2rem;
        box-sizing: border-box;
        position: relative;
        z-index: 20;
        flex-grow: 2;
        flex-shrink: 0;

        .demo {
          position: relative;
          width: 100%;
          max-width: 90vw;
          box-shadow: $shadow;
          transform: translateX(30%) scale(1.6);

          @media screen and (max-width: 1200px) {
            & {
              transform: none;
            }
          }
        }
      }

      .backgroundWrapper {
        width: 50%;
        position: absolute;

        .background {
          width: 100vh;
          height: 100vh;
          border-radius: 100vh;
          background: $background_2_light;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 0;
          position: absolute;

          @media (prefers-color-scheme: dark) {
            & {
              background: rgba(0, 0, 0, 1);
            }
          }
        }
      }

      .content {
        max-width: 600px;
        display: block;
        position: relative;
        height: 100%;
        width: 90vh;
        flex-grow: 1;
        flex-shrink: 1;
        z-index: 10;

        .landing-parallax {
          position: absolute;
          bottom: 0;
          width: 100%;
        }

        .joinButton {
          border: 3px solid $background_2_light;

          @media (prefers-color-scheme: dark) {
            & {
              border: none;
            }
          }
        }

        .introText {
          position: relative;
          box-sizing: border-box;
          padding: 2rem;
          color: $accent_dark;
          z-index: 100;

          @media (prefers-color-scheme: dark) {
            & {
              color: white;
            }
          }

          .headings {
            margin: 3vh 0;

            h1, h2 {
              padding: 0;
              margin: 0;
              font-size: 4vh;
              text-align: left;

              &.subheading {
                font-weight: normal;
                font-size: 2vh;
              }
            }
          }
        }
      }
    }
  }
}