@import "../frontend/src/variables.module";

#usp {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: $background_light;

  @media (prefers-color-scheme: dark) {
    & {
      background: $background_dark;
    }
  }

  .contentWrapper {
    padding: 2rem 2rem 15%;
  }

  .separator {
    fill: $accent_dark;

    @media (prefers-color-scheme: dark) {
      & {
        fill: $background_2_dark;
      }
    }
  }

  .aboutCards {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 4rem;
    align-items: stretch;
    align-content: flex-start;

    .card {
      color: $accent_light;
      flex-shrink: 1;
      flex-grow: 1;
      width: 300px;
      background: $background_2_light;
      margin: 0.75rem;
      padding: 2rem;
      text-align: center;
      border-radius: $border_radius;
      transition: transform 0.2s, opacity 0.2s;

      @media (prefers-color-scheme: dark) {
        & {
          box-shadow: $shadow_dark;
        }
      }

      @media (prefers-color-scheme: dark) {
        & {
          background: $background_2_dark;
          color: $accent_dark;
        }
      }

      svg {
        position: relative;
        background: $accent_light;
        display: block;
        margin: 1rem auto;
        padding: 1rem;
        font-size: 3rem;
        border-radius: $border_radius;
      }

      .heart {
        fill: red;
        background: #ffd3d3;
      }

      .clock {
        fill: #537a78;
        background: #d1fffc;
      }

      .lock {
        fill: #565f62;
        background: $accent_light;
      }

      &:hover {
        opacity: 1;
        transform: translateY(-5px) scale(1.01);
      }

      label {
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: bolder;
        color: $headline_2_light;

        @media (prefers-color-scheme: dark) {
          & {
            color: $headline_2_dark;
          }
        }
      }

      p {
        color: $button_font_light;
        @media (prefers-color-scheme: dark) {
          & {
            color: $button_font_dark;
          }
        }
      }
    }
  }

}