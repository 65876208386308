@import "../frontend/src/variables.module";

html, body {
  margin: 0;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100vw;
}

a:link, a:visited {
    text-decoration: none;
    color: inherit;
    transition: transform 0.2s, opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.decoration {
  background: $background_dark url("./rsc/background.png");
  background-size: 3rem;
  width: 30vw;
  height: 30vw;
  max-width: 80vw;
  max-height: 80vw;

  position: absolute;

  bottom: 0;
  left: 0;
  transform: translate(0%, -15vw);
  z-index: -1;
  border-radius: 30vw;

  //@media (prefers-color-scheme: dark) {
  //  & {
  //    background: rgb(15, 38, 52);
  //  }
  //}

}

.separator {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: auto;

  transform: translateY(1px);

  stroke: transparent;
  stroke-width: 0px;
  border: none;

  path {
    stroke: transparent;
    stroke-width: 0px;
    border: none;
  }

}

.contentWrapper {
  max-width: 1200px;
  margin: auto;
  bottom: 0;
  z-index: 5;

  div, h1, h2, h3, a, p {
    z-index: 3;
  }

  h1, h2, h3, a, p {
    position: relative;
  }

  h1 {
    font-size: 3rem;
    color: $accent_dark;
    padding: 1rem 0 0;
    text-align: center;
    margin: 0;

    @media (prefers-color-scheme: dark) {
      & {
        color: $accent_light;
      }
    }
  }

  h2.subtitle {
    font-size: 1.5rem;
    text-align: center;
    font-weight: normal;
    padding: 0;
    margin: 0 0 2rem;
    color: black;

    @media (prefers-color-scheme: dark) {
      & {
        color: $headline_light;
      }
    }
  }
}

.dark {
  display: none;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  .dark {
    display: unset;
  }
  .light {
    display: none;
  }
}

.buttons {
    display: flex;
    flex-direction: row;

    .button, .button:link, .button:visited {
      border-radius: 3rem;
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      color: $button_font_light;
      border: 2px solid $accent_dark;
      text-decoration: none;
      margin-left: 0.5rem;

      transition: opacity 0.5s;

      @media (prefers-color-scheme: dark) {
        & {
          border: 2px solid $accent_light;
          color: $button_font_dark;
        }
        }

        &:hover {
            opacity: 0.5;
        }

        &.solid {
          background: $accent_dark;
          color: $button_font_dark;

          @media (prefers-color-scheme: dark) {
            & {
              background: $accent_light;
              color: $button_font_light;
            }
          }
        }
    }
}
