@import "../frontend/src/variables.module";

#imprint, #privacy, #terms, .imprintComponent {
  background: $background_light;

  .contentWrapper {
    padding: 5rem 2rem;
    position: relative;
    color: black;
    margin: auto auto;
    text-align: left;

    h1, h2, h3 {
      color: $accent_dark;
    }

    h1, h2, a, li, p {
      text-align: left;
    }

    @media (prefers-color-scheme: dark) {

      background: $background_dark;
      a, li, p {
        color: $font_light
      }

      h1, h2, h3 {
        color: $accent_light;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: left;
    justify-items: left;
    align-items: start;
    align-content: start;

    .button {
      display: block;
      position: relative;
      text-align: center;
      margin: 0 0.5rem 0 0;
      background: $accent_dark;
      color: white;
      padding: 0.7rem 1.4rem;
      border: 2px solid $accent_dark;
      border-radius: 2rem;

      &.outlined {
        background: transparent;
        border: 2px solid $accent_dark;
        color: $accent_dark;
      }
    }
  }

}