@import "../frontend/src/variables.module";

#tutorial {
  position: relative;
  overflow: hidden;
  padding: 2rem 0 5%;

  background: $accent_dark;
  @media (prefers-color-scheme: dark) {
    & {
      background: $background_2_dark;
    }
  }

  .christmasyacht {
    float: left;
    width: 6rem;
    height: 6rem;
    transform: rotate(20deg);
    transition: transform 0.5s;
    z-index: 1000;

    &:hover {
      transform: scale(1.5);
    }
  }

  .separator {
    fill: $background_light;

    @media (prefers-color-scheme: dark) {
      & {
        fill: $background_dark;
      }
    }
  }


  .contentWrapper {
    padding: 2rem;
    padding-top: 0;
    padding-bottom: 10%;
  }

  h1 {
    color: $headline_light;
  }

  h2 {
    color: $accent_light;
  }

  .list {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    align-items: stretch;
    flex: 1;
    padding-bottom: 5rem;

    .listItem {
      z-index: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-content: center;
      align-items: center;
      justify-content: center;
      justify-items: center;
      margin: 0.75rem;
      min-width: 200px;
      flex: 1;
      background: $background_light;
      border-radius: $border_radius;
      padding: 4rem 3rem;
      overflow: hidden;
      transition: transform 0.2s;

      box-shadow: $shadow;
      color: $button_font_light;

      &:hover {
        transform: scale(1.01);
      }

      @media (prefers-color-scheme: dark) {
        & {
          box-shadow: $shadow_dark;
          color: $button_font_dark;
          background: $background_2_dark;
        }
      }

      .itemIcon {
        color: $accent_light;
        text-align: center;
        flex-shrink: 0;
        flex-grow: 0;
        padding-bottom: 2rem;
        width: 4rem;
        font-size: 2rem;
        box-sizing: border-box;

        @media (prefers-color-scheme: dark) {
          & {
            color: $accent_dark;
          }
        }

      }

      .itemText {
        h3 {
          color: $accent_dark;
          padding-top: 0;
          margin-top: 0;

          @media (prefers-color-scheme: dark) {
            & {
              color: $accent_light;
            }
          }
        }
      }

      .animateicon {
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        &#cursoricon {
          animation-name: cursor_blink;
        }

        &#mouseicon {
          animation-name: mouse_move;
        }

        &#volumeicon {
          animation-name: volume_fade;
        }
      }
    }
  }

  .join {
    display: block;
    position: relative;
    margin: auto;
    text-align: center;
    width: 30%;
    box-shadow: $shadow;
    margin-bottom: 1rem;
    background: $background_light;
    color: $accent_dark;
    &.outlined {
      background: transparent;
      margin-bottom: 3rem;
      border: 2px solid white;
      color: white;
    }
  }

  @keyframes mouse_move {
    from {
      transform: translateX(-5%) rotate(-5deg);
    }

    to {
      transform: translateX(5%) rotate(5deg);
    }
  }

  @keyframes volume_fade {
    from {
      transform: translateX(-3%);
    }

    to {
      transform: translateX(3%);
    }
  }

  @keyframes cursor_blink {
    from {
      opacity: 1;
    }
    70% {
      opacity: 0.9;
    }
    to {
      opacity: 0.1;
    }
  }

}